import React from "react";
import Layout from "../components/layout";
import styles from "../styles/pages.module.css";

export default function Privacy() {
	return (
		<Layout>
			<div className={styles.bodyText}>
				<h4>Privacy Policy</h4>
				<span>
					<i>Effective Date: 1-Dec-2024</i>
				</span>
				<br />
				<br />
				<p>
					<h5>Introduction</h5>
					Welcome to <b>DebjyotiAcharjee.com</b>. Your privacy is
					important and therefore this Privacy Policy outlines how the
					data is collected, used, disclosed, and safeguarded when you
					visit our website. Please read this policy carefully. If you
					do not agree with the terms of this Privacy Policy, please
					do not access the site.
				</p>
				<p>
					<h5>Information We Collect</h5>
					We may collect information about you in a variety of ways,
					including:
					<ul>
						<li>
							<b>Personal Data: </b>
							Personally identifiable information, such as your
							name, email address, and contact details, that you
							voluntarily give to us when you register on the site
							or contact us.
						</li>
						<li>
							<b>Usage Data: </b>
							Information about your interactions with our
							website, including your IP address, browser type,
							operating system, pages visited, time and date of
							visits, and other diagnostic data.
						</li>
						<li>
							<b>Cookies and Tracking Technologies: </b>
							We may use cookies, web beacons, pixels, and other
							tracking technologies to collect information about
							your activity on our site.
						</li>
					</ul>
				</p>
				<p>
					<h5>How We Use Your Information</h5>
					We may use the information we collect in the following ways:
					<ul>
						<li>To operate and maintain our website.</li>
						<li>
							To improve, personalize, and expand our website.
						</li>

						<li>
							To understand and analyze how you use our website.
						</li>
						<li>
							To communicate with you, either directly or through
							one of our partners, including for customer service,
							to provide you with updates and other information
							relating to the website.
						</li>
						<li>
							To process your transactions and send you relevant
							information.
						</li>
						<li>
							To send you emails regarding your order or other
							products and services.
						</li>
					</ul>
				</p>
				<p>
					<h5>Disclosure of Your Information</h5>
					We may share information we have collected about you in
					certain situations. Your information may be disclosed as
					follows:
					<ul>
						<li>
							<b>By Law or to Protect Rights:</b> If we believe
							the release of information about you is necessary to
							respond to legal process, to investigate or remedy
							potential violations of our policies, or to protect
							the rights, property, and safety of others, we may
							share your information as permitted or required by
							any applicable law, rule, or regulation.
						</li>
						<li>
							<b>Third-Party Service Providers:</b> We may share
							your information with third parties that perform
							services for us or on our behalf, including payment
							processing, data analysis, email delivery, hosting
							services, customer service, and marketing
							assistance.
						</li>
					</ul>
				</p>
				<p>
					<h5>Security of Your Information</h5>
					We use administrative, technical, and physical security
					measures to help protect your personal information. While we
					have taken reasonable steps to secure the personal
					information you provide to us, please be aware that no
					method of transmission over the Internet or method of
					electronic storage is 100% secure, and we cannot guarantee
					its absolute security.
				</p>
				<p>
					<h5>Your Rights</h5>
					Depending on your location, you may have the following
					rights regarding your personal information:
					<ul>
						<li>
							<b>The right to access -</b> You have the right to
							request copies of your personal data.
						</li>
						<li>
							<b>The right to rectification -</b> You have the
							right to request that we correct any information you
							believe is inaccurate.
						</li>
						<li>
							<b>The right to erasure -</b> You have the right to
							request that we erase your personal data, under
							certain conditions.
						</li>
						<li>
							<b>The right to restrict processing -</b> You have
							the right to request that we restrict the processing
							of your personal data, under certain conditions.
						</li>
						<li>
							<b>The right to data portability -</b> You have the
							right to request that we transfer the data that we
							have collected to another organization, or directly
							to you, under certain conditions.
						</li>
					</ul>
				</p>
				<p>
					<h5>Changes to This Privacy Policy</h5>
					We may update our Privacy Policy from time to time. We will
					notify you of any changes by posting the new Privacy Policy
					on this page. You are advised to review this Privacy Policy
					periodically for any changes. Changes to this Privacy Policy
					are effective when they are posted on this page.
				</p>
				<p>
					<h5>Contact Us</h5>
					If you have any questions about this Privacy Policy, please
					contact us: <i>itsme@debjyotiacharjee.com</i>
				</p>
			</div>
		</Layout>
	);
}
